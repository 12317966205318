import moment from "moment";

export function getPeriodString(dates) {
  const { startDate, endDate } = dates;

  const currentDate = moment.utc(startDate ?? moment().toDate());

  const lastDate = moment.utc(endDate ?? moment().toDate());

  const diffInDays = lastDate.diff(currentDate, "days"); // Add 1 to account for both start and end dates

  const diffInMonths = lastDate.diff(currentDate, "months");

  const diffInYears = lastDate.diff(currentDate, "years");

  if (diffInDays === 0) {
    return "Today";
  } else if (diffInDays === 1) {
    return "Last 24h";
  } else if (diffInDays < 31) {
    return `Last ${diffInDays} days`;
  } else if (diffInDays < 365) {
    return `Last ${diffInMonths} months`;
  } else if (diffInYears < 2) {
    return `Last ${diffInYears} year`;
  } else {
    return `Last ${diffInYears} years`;
  }
}

export function notAfterToday(date) {
  return moment(date).isAfter(moment());
}

export function formatCurrency(value) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "MAD",
  }).format(value || 0);
}
