import axios from "../common/axios";

export async function costPerDelivery(params) {
  const { data } = await axios.get("/orders/costPerDelivery", { params });
  return data;
}

export async function ordersRate(params) {
  const { data } = await axios.get("/orders/rate", {
    params,
  });
  return data;
}

export async function ordersTimeseries(params) {
  const { data } = await axios.get("/orders/timeseries", { params });
  return data;
}


export async function costPerDeliveryGlobal(params) {
  const { data } = await axios.get("/orders/costPerDeliveryGlobal", { params });
  return data;
}

export async function ordersRateGlobal(params) {
  const { data } = await axios.get("/orders/rateGlobal", {
    params,
  });
  return data;
}
