<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke-width="2"
    stroke="currentColor"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M7 7l10 10" />
    <path d="M17 8l0 9l-9 0" />
  </svg>
</template>

<script>
export default {
  name: "ArrowDownRight",
};
</script>
