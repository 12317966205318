<script>
import VueApexCharts from "vue-apexcharts";

const CHART_OPTIONS = {
  chart: {
    height: 350,
    type: "area",
    stacked: true,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    type: "datetime",
  },

  title: {
    text: "Cost By Delivery",
    align: "left",
    offsetX: 14,
  },
};

const OPTIONS = {
  chart: {
    type: "bar",
    height: 350,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  xaxis: {
    type: "datetime",
  },

  fill: {
    opacity: 1,
  },
};
export default {
  name: "CostPerDeliveryWidget",
  components: {
    VueApexCharts,
  },
  props: {
    costs: {
      type: Object,
      default: () => ({
        CPDAds: 0,
        CPDCharges: 0,
        CPDTotal: 0,
        profitsPerDelivery: 0,
        revenues: 0,
        roi: 0,
        totalProfits: 0,
        totalSpends: 0,
      }),
    },
    series: {
      type: Array,
    },
  },

  data: () => ({
    chartOptions: OPTIONS,
  }),

  methods: {
    formatCurrency(value, short = false) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value || 0);
    },
  },

  computed: {
    _chartOptions() {
      return {
        ...this.chartOptions,
        yaxis: {
          labels: {
            formatter: (value) => this.formatCurrency(value, true),
          },
        },
        tooltip: {
          x: {
            format:
              this.series[0].data?.length === 24 ? "dd/MM/yy:HH:mm" : "MM/yy",
          },
        },
      };
    },
  },
};
</script>

<template>
  <div
    class="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
  >
    <div
      class="p-6 md:flex gap-4 justify-between flex-wrap mb-6 space-y-4 md:space-y-0"
    >
      <div>
        <h3 class="text-lg font-semibold">Total cost per delivery</h3>
        <span class="text-2xl font-bold text-black">
          {{ formatCurrency(costs.CPDTotal) }}
        </span>
      </div>
      <div>
        <h3 class="text-lg font-semibold">Cost per delivery ADS</h3>
        <span class="text-2xl font-bold text-black">
          {{ formatCurrency(costs.CPDAds) }}
        </span>
      </div>
      <div>
        <h3 class="text-lg font-semibold">Cost per delivery charges</h3>
        <span class="text-2xl font-bold text-black">
          {{ formatCurrency(costs.CPDCharges) }}
        </span>
      </div>
      <div>
        <h3 class="text-lg font-semibold">Revenues</h3>
        <span class="text-2xl font-bold text-black">
          {{ formatCurrency(costs.revenues) }}
        </span>
      </div>
      <div>
        <h3 class="text-lg font-semibold">Profits</h3>
        <span
          class="text-2xl font-bold text-black"
          :class="{
            'text-red-500': costs.totalProfits < 0,
          }"
        >
          {{ formatCurrency(costs.totalProfits) }}
        </span>
      </div>
      <div>
        <h3 class="text-lg font-semibold">Return on investment</h3>
        <span
          class="text-2xl font-bold text-black"
          :class="{
            'text-red-500': costs.roi < 0,
          }"
        >
          {{ costs.roi?.toFixed(0) || 0 }}%
        </span>
      </div>
    </div>
    <VueApexCharts
      type="bar"
      height="350"
      :options="_chartOptions"
      :series="series"
    />
  </div>
</template>
