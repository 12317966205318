var render = function render(){
  var _vm$costs$roi;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
  }, [_c('div', {
    staticClass: "p-6 md:flex gap-4 justify-between flex-wrap mb-6 space-y-4 md:space-y-0"
  }, [_c('div', [_c('h3', {
    staticClass: "text-lg font-semibold"
  }, [_vm._v("Total cost per delivery")]), _c('span', {
    staticClass: "text-2xl font-bold text-black"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.costs.CPDTotal)) + " ")])]), _c('div', [_c('h3', {
    staticClass: "text-lg font-semibold"
  }, [_vm._v("Cost per delivery ADS")]), _c('span', {
    staticClass: "text-2xl font-bold text-black"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.costs.CPDAds)) + " ")])]), _c('div', [_c('h3', {
    staticClass: "text-lg font-semibold"
  }, [_vm._v("Cost per delivery charges")]), _c('span', {
    staticClass: "text-2xl font-bold text-black"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.costs.CPDCharges)) + " ")])]), _c('div', [_c('h3', {
    staticClass: "text-lg font-semibold"
  }, [_vm._v("Revenues")]), _c('span', {
    staticClass: "text-2xl font-bold text-black"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.costs.revenues)) + " ")])]), _c('div', [_c('h3', {
    staticClass: "text-lg font-semibold"
  }, [_vm._v("Profits")]), _c('span', {
    staticClass: "text-2xl font-bold text-black",
    class: {
      'text-red-500': _vm.costs.totalProfits < 0
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.costs.totalProfits)) + " ")])]), _c('div', [_c('h3', {
    staticClass: "text-lg font-semibold"
  }, [_vm._v("Return on investment")]), _c('span', {
    staticClass: "text-2xl font-bold text-black",
    class: {
      'text-red-500': _vm.costs.roi < 0
    }
  }, [_vm._v(" " + _vm._s(((_vm$costs$roi = _vm.costs.roi) === null || _vm$costs$roi === void 0 ? void 0 : _vm$costs$roi.toFixed(0)) || 0) + "% ")])])]), _c('VueApexCharts', {
    attrs: {
      "type": "bar",
      "height": "350",
      "options": _vm._chartOptions,
      "series": _vm.series
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }