<template>
    <div class="flex flex-col justify-center w-full items-start gap-3 my-5">
        <div>
            <h1 class="text-2xl font-bold">Product Analytics by tags</h1>
            <p class="text-gray-600 tracking-wide">
                Get insights about your product by tags
            </p>
        </div>

        <DataTable :value="AnalyticsperTag" paginator responsiveLayout="scroll" dataKey="_id"
            style="border-radius: 10px !important ; margin-top: 10px ; width : 100%" :loading="isLoading" :rows="10"
            :rowsPerPageOptions="[10, 20, 50]"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
            <Column field="details.tag" header="Tag Name" style="flex: 0 0 4rem">
                <template #body="{ data }">
                    <div class="bg-green-100 rounded px-3 py-1 flex justify-center items-center">
                        <span class="text-md font-semibold text-green-600 ">
                            {{ data.tagName || data._id  }}
                        </span>
                    </div>

                </template>
            </Column>
            <Column field="details.items" header="Total Orders" style="flex: 0 0 4rem">
                <template #body="{ data }">
                    <div class="flex justify-center items-center">
                        <span class="text-lg font-semibold  ">
                            {{ getTotalByStatue(data.items , 'total') }} <span class="text-xs font-normal">orders</span> 
                        </span>
                    </div>

                </template>
            </Column>

            <Column field="details.items" header="Delivered Orders">
                <template #body="{ data }">
                    <div class="flex justify-center items-center">
                        <span class="text-lg font-semibold  ">
                            {{ getTotalByStatue(data.items , 'delivered') }} <span class="text-xs font-normal">orders</span> 
                        </span>
                    </div>

                </template>
            </Column>
            <Column field="details.items" header="Confirmed Orders">
                <template #body="{ data }">
                    <div class="flex justify-center items-center">
                        <span class="text-lg font-semibold  ">
                            {{ getTotalByStatue(data.items , 'confirmed') }} <span class="text-xs font-normal">orders</span> 
                        </span>
                    </div>

                </template>
            </Column>
            <Column field="details.CPDTotal" header="Total cost per delivery"><template #body="{ data }">
                    <div class="flex justify-center items-center">
                        <span class="text-md font-semibold  ">
                            {{
                                new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                }).format(data.CPDTotal)
                            }}
                        </span>
                    </div>

                </template>

            </Column>
            <Column field="details.CPDAds" header="Cost per delivery ADS"><template #body="{ data }">
                    <div class="flex justify-center items-center">
                        <span class="text-md font-semibold  ">
                            {{
                                new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                }).format(data.CPDAds)
                            }}
                        </span>
                    </div>

                </template>
            </Column>
            <Column field="details.CPDCharges" header="Cost per delivery charges"><template #body="{ data }">
                    <div class="flex justify-center items-center">
                        <span class="text-md font-semibold  ">
                            {{
                                new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                }).format(data.CPDCharges)
                            }}
                        </span>
                    </div>

                </template>
            </Column>
            <Column field="details.revenues" header="Revenues"><template #body="{ data }">
                    <div class="flex justify-center items-center">
                        <span class="text-md font-semibold  ">
                            {{
                                new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                }).format(data.revenues)
                            }}
                        </span>
                    </div>

                </template>
            </Column>
            <Column field="details.profitsPerDelivery" header="Profits per delivery" style="flex: 0 0 4rem"><template #body="{ data }">
                    <div class="flex justify-center items-center">
                        <span class="text-md font-semibold  ">
                            {{
                                new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                }).format(data.profitsPerDelivery)
                            }}
                        </span>
                    </div>

                </template>
            </Column>
            <Column field="details.totalProfits" header="Profits" style="flex: 0 0 4rem"><template #body="{ data }">
                    <div class="flex justify-center items-center">
                        <span class="text-md font-semibold  ">
                            {{
                                new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                }).format(data.totalProfits)
                            }}
                        </span>
                    </div>

                </template>
            </Column>
            <Column field="details.roi" header="Return on investment"><template #body="{ data }">
                    <div class="flex justify-center items-center gap-2">
                        <span class="text-md font-semibold  text-green-500">
                            {{ data.roi }}%

                        </span>
                        <div class="relative w-full">
                            <div class="overflow-hidden h-2 text-xs flex rounded bg-green-200">
                                <div :style="{ width: (data.roi) + '%' }"
                                    class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500">
                                </div>
                            </div>
                        </div>
                    </div>

                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { ordersRateGlobal, costPerDeliveryGlobal } from "../../api/orders.api";
import { getProduct } from "../../api/products.api";
import ArrowUpRight from "@/common/icons/ArrowUpRight.vue";
import ArrowDownRight from "@/common/icons/ArrowDownRight.vue";


const DEFAULT_RATE = {
    current: 0,
    rate: 0,
};
export default {
    components: {
        DataTable,
        Column
    },
    props: {
        idWharhouse: { type: Object },
        filterOptions: { type: Object },
    },
    data() {
        return {
            isLoading: false,
            AnalyticsperTag: [],
            product: null,
        };
    },
    async mounted() {
        await this.fetchDataGlobal()
    },
    watch: {
        idWharhouse: async function (oldVal, newVal) {
            await this.fetchDataGlobal();
        },
        filterOptions: {
            handler: 'fetchDataGlobal',
            deep: true
        }
    },
    methods: {
        getTotalByStatue(data , status){
           const result = data.find(item => item.name.toLowerCase() === status.toLowerCase())
           return result?.total || 0
        },
        async fetchDataGlobal() {
            this.isLoading = true;
            let Tag = null;


            const product = await getProduct(this.filterOptions.productId);
            this.product = product.content;

            const [oRates, oCosts] = await Promise.all([
                ordersRateGlobal({
                    tags: this.product.tags,
                    country: this.idWharhouse.country,
                    status: "Delivered,Confirmed",
                    startDate: this.filterOptions.startDate,
                    endDate: this.filterOptions.endDate,
                    productId: this.filterOptions.productId
                }),
                costPerDeliveryGlobal({
                    tags: this.product.tags,
                    country: this.idWharhouse.country,
                    startDate: this.filterOptions.startDate,
                    endDate: this.filterOptions.endDate,
                    productId: this.filterOptions.productId
                })
            ]);

            this.isLoading = false;

            const defaultValues = {
                totalExpense: 0,
                totalSalariesExpenses: 0,
                totalShippingExpenses: 0,
                totalProductsCost: 0,
                totalSpends: 0,
                CPDAds: 0,
                CPDCharges: 0,
                CPDTotal: 0,
                aov: 0,
                roi: 0,
                totalProfits: 0,
                profitsPerDelivery: 0,
                revenues: 0
            };

            const mergedArray = oRates.content.data.map(item1 => {
                const matchingItem = oCosts.content.costs.find(item2 => item2.tagName === item1._id);
                return {
                    ...item1,
                    ...(matchingItem || defaultValues) 
                };
            });
            this.AnalyticsperTag = mergedArray





        },
    }

};
</script>

<style scoped></style>
