<script>
import moment from "moment";

import VueApexCharts from "vue-apexcharts";
import Dropdown from "primevue/dropdown";
import CostPerDeliveryWidget from "../../components/widgets/CostPerDelivery.vue";
import ArrowDownRight from "../../common/icons/ArrowDownRight.vue";
import ArrowUpRight from "../../common/icons/ArrowUpRight.vue";

import {
  costPerDelivery,
  ordersRate,
  ordersTimeseries,
} from "../../api/orders.api";
import { getProduct } from "../../api/products.api";
import Vue2DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { getPeriodString } from "../../common/utils";
import Button from 'primevue/button'
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'
import Dialog from 'primevue/dialog'
import TbleTags from "../../components/Analytics/TbleTags.vue";



const SHORTCUTS = [
  {
    text: "Today",
    onClick() {
      const start = moment().toDate();
      const end = moment().toDate();
      return [start, end];
    },
  },
  {
    text: "Last 24h",
    onClick() {
      const start = moment().subtract(1, "days").toDate();
      const end = moment().toDate();
      return [start, end];
    },
  },
  {
    text: "This week",
    onClick() {
      const start = moment().startOf("week").toDate();
      const end = moment().endOf("week").toDate();
      return [start, end];
    },
  },
  {
    text: "Last week",
    onClick() {
      const start = moment().subtract(1, "weeks").startOf("week").toDate();
      const end = moment().subtract(1, "weeks").endOf("week").toDate();
      return [start, end];
    },
  },
  {
    text: "This Month",
    onClick() {
      const start = moment().startOf("month").toDate();
      const end = moment().endOf("month").toDate();
      // return an array of two dates
      return [start, end];
    },
  },
  {
    text: "Last Month",
    onClick() {
      const start = moment().subtract(1, "months").startOf("month").toDate();
      const end = moment().subtract(1, "months").endOf("month").toDate();
      return [start, end];
    },
  },
  {
    text: "This Year",
    onClick() {
      const start = moment().startOf("year").toDate();
      const end = moment().endOf("year").toDate();
      return [start, end];
    },
  },
  {
    text: "Last Year",
    onClick() {
      const start = moment().subtract(1, "year").startOf("year").toDate();
      const end = moment().subtract(1, "year").endOf("year").toDate();
      return [start, end];
    },
  },
];

const CHART_OPTIONS = {
  chart: {
    height: 350,
    type: "area",
    stacked: true,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    type: "datetime",
  },

  tooltip: {
    x: {
      format: "dd/MM/yy",
    },
  },
};

const OPTIONS = {
  chart: {
    type: "bar",
    height: 350,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  xaxis: {
    type: "datetime",
  },

  fill: {
    opacity: 1,
  },
};
const COPTIONS = {
  chart: {
    type: "area",
    stacked: false,
    height: 350,
    zoom: {
      type: "x",
      enabled: true,
      autoScaleYaxis: true,
    },
    toolbar: {
      autoSelected: "zoom",
    },
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
  },
  title: {
    text: "Total Order By Status",
    align: "left",
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.5,
      opacityTo: 0,
      stops: [0, 90, 100],
    },
  },
  xaxis: {
    type: "datetime",
  },
};

const DEFAULT_RATE = {
  current: 0,
  rate: 0,
};

export default {
  name: "ProductAnalytics",
  props: {
    currentUser: { type: Object },
    idWharhouse: { type: Object },
  },
  components: {
    DataTable,
    Column,
    Button,
    TbleTags,
    Dialog,
    VueApexCharts,
    CostPerDeliveryWidget,
    Vue2DatePicker,
    ArrowDownRight,
    ArrowUpRight,
    Dropdown,

  },
  data: () => ({
    chartOptions: CHART_OPTIONS,
    shorts: SHORTCUTS,
    COPTIONS: OPTIONS,
    isLoading: false,
    product: null,
    tags: [],
    orders: {
      rates: {
        data: {
          confirmed: DEFAULT_RATE,
          delivered: DEFAULT_RATE,
          total: DEFAULT_RATE,
        },
        series: [],
      },
      costs: {
        data: {
          CPDAds: 0,
          CPDCharges: 0,
          CPDTotal: 0,
          profitsPerDelivery: 0,
          revenues: 0,
          roi: 0,
          totalProfits: 0,
          totalSpends: 0,
        },
        series: [],
      },
    },



    period: "",
    selectedTag: null,
    selectedDates: [moment().toDate(), moment().toDate()],
    filterOptions: {
      productId: null,
      tag: null,
      startDate: null,
      endDate: null,
    },
  }),
  watch: {
    selectedTag(tag) {
      this.filterOptions.tag = tag?.value;
      this.fetchData(this.filterOptions);
    },
    idWharhouse: async function (oldVal, newVal) {
      // this.idWharhouse = oldVal;
      this.fetchData(this.filterOptions);
    },
    selectedDates(dates) {
      const now = moment();

      const isSameDate = moment(dates[0]).isSame(dates[1], "day");
      if (!dates || (!dates[0] && !dates[1]) || isSameDate) {
        dates[0] = now.clone().utc().startOf("day").unix();
        dates[1] = now.clone().utc().endOf("day").unix();
      }

      this.filterOptions.startDate = dates[0]
        ? moment.utc(dates[0]).unix()
        : null;
      this.filterOptions.endDate = dates[1]
        ? moment.utc(dates[1]).unix()
        : null;

      this.period = getPeriodString({
        startDate: dates[0],
        endDate: dates[1],
      });
      this.fetchData(this.filterOptions);
    },
  },
  methods: {
    notAfterToday(date) {
      return moment(date).isAfter(moment());
    },



    async fetchData(params) {
      const productId = this.$route.params.id;
      this.isLoading = true;

      const [
        product,
        oRates,
        oCosts,
        timeseriesRatesC,
        timeseriesRatesD,
        timeseriesCosts,
      ] = await Promise.all([
        getProduct(productId),
        ordersRate({ productId, country: this.idWharhouse.country, status: "Delivered,Confirmed", ...params }),
        costPerDelivery({ productId, country: this.idWharhouse.country, ...params }),
        ordersTimeseries({
          status: "Confirmed",
          productId,
          country: this.idWharhouse.country,

          ...params,
        }),
        ordersTimeseries({
          status: "Delivered",
          productId,
          country: this.idWharhouse.country,
          ...params,
        }),
        ordersTimeseries({ productId, type: "costs", country: this.idWharhouse.country, ...params }),
      ]);

      this.product = product.content;
      this.tags = product.content.tags.map((tag) => ({
        label: tag,
        value: tag,
      }));
      this.orders.rates.data = oRates.content.data.reduce((acc, rate) => {
        acc[rate.name.toLowerCase()] = rate;
        return acc;
      }, {});
      this.orders.rates.series = [
        {
          name: "Confirmed",
          data: timeseriesRatesC.content,
        },
        {
          name: "Delivered",
          data: timeseriesRatesD.content,
        },
      ];
      this.orders.costs.data = oCosts.content.costs;
      this.orders.costs.series = [
        {
          name: "ADS",
          data: timeseriesCosts.content.ads,
        },
        {
          name: "Charges",
          data: timeseriesCosts.content.charges,
        },
        {
          name: "Total",
          data: timeseriesCosts.content.total,
        },
      ];

      this.isLoading = false;
    },

    formatCurrency(value, short = false) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        ...(short && {
          notation: "compact",
          compactDisplay: "short",
          maximumSignificantDigits: 2,
        }),
      }).format(value || 0);
    },
  },
  mounted() {
    this.fetchData();
    this.period = getPeriodString({
      startData: moment().toDate(),
      endDate: moment().toDate(),
    });

    this.filterOptions.productId = this.$route.params.id;
  },
};
</script>

<template>
  <div class="py-8 px-6">
    <div v-if="isLoading" style="background-color: rgb(0 0 0 / 27%)"
      class="text-3xl font-bold text-center fixed w-full h-full left-0 top-0 right-0 bottom-0 z-50 flex items-center justify-center">
      <svg class="animate-spin h-12 w-12 text-green-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
        stroke-linejoin="round">
        >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M12 3a9 9 0 1 0 9 9"></path>
      </svg>
    </div>
    <div class="flex items-center justify-between mb-8 flex-wrap gap-6">
      <div>
        <h1 class="text-2xl font-bold">Product Analytics</h1>
        <p class="text-gray-600 tracking-wide">
          Get insights about your product
        </p>
      </div>
      <div class="flex gap-4 flex-wrap w-full md:w-auto">
        <Dropdown v-model="selectedTag" :options="tags" optionLabel="label" showClear placeholder="Select Tag"
          class="w-full md:w-64" />
        <Vue2DatePicker v-model="selectedDates" class="w-full md:w-64" range placeholder="Select date range"
          :disabled-date="notAfterToday" :shortcuts="shorts" />
      </div>
    </div>
    <div class="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 my-6">
      <div class="p-6 grid grid-cols-1 gap-2 sm:gap-0 sm:grid-cols-3 mb-6 space-y-4 md:space-y-0">
        <div>
          <h3 class="text-lg font-semibold">Total Orders</h3>
          <div>
            <p class="text-gray-600 tracking-wide">
              <span class="text-3xl font-bold text-black">
                {{ orders.rates.data.total.current }}
              </span>
              orders
            </p>
            <p class="text-gray-600 text-sm tracking-wider flex gap-2">
              <span class="font-bold flex items-center justify-center" :class="{
                'text-green-500': orders.rates.data.total.rate > 0,
                'text-red-500': orders.rates.data.total.rate < 0,
              }">
                <ArrowDownRight class="text-xs w-4 h-4" v-if="orders.rates.data.total.rate < 0" :size="16" />
                <ArrowUpRight v-if="orders.rates.data.total.rate > 0" :size="16" class="text-xs w-4 h-4" />
                {{ orders.rates.data.total.rate }} %
              </span>
              {{ period }}
            </p>
          </div>
        </div>
        <div>
          <h3 class="text-lg font-semibold">Delivered Orders</h3>
          <div>
            <p class="text-gray-600 tracking-wide">
              <span class="text-3xl font-bold text-black">
                {{ orders.rates.data.delivered.current }}
              </span>
              orders
            </p>
            <p class="text-gray-600 text-sm tracking-wider flex gap-2">
              <span class="font-bold flex items-center justify-center" :class="{
                'text-green-500': orders.rates.data.delivered.rate > 0,
                'text-red-500': orders.rates.data.delivered.rate < 0,
              }">
                <ArrowDownRight class="text-xs w-4 h-4" v-if="orders.rates.data.delivered.rate < 0" :size="16" />
                <ArrowUpRight v-if="orders.rates.data.delivered.rate > 0" :size="16" class="text-xs w-4 h-4" />
                {{ orders.rates.data.delivered.rate }} %
              </span>
              {{ period }}
            </p>
          </div>
        </div>
        <div>
          <h3 class="text-lg font-semibold">Confirmed Orders</h3>
          <div>
            <p class="text-gray-600 tracking-wide">
              <span class="text-3xl font-bold text-black">
                {{ orders.rates.data.confirmed.current }}
              </span>
              orders
            </p>
            <p class="text-gray-600 text-sm tracking-wider flex gap-2">
              <span class="font-bold flex items-center justify-center" :class="{
                'text-green-500': orders.rates.data.confirmed.rate > 0,
                'text-red-500': orders.rates.data.confirmed.rate < 0,
              }">
                <ArrowDownRight class="text-xs w-4 h-4" v-if="orders.rates.data.confirmed.rate < 0" :size="16" />
                <ArrowUpRight v-if="orders.rates.data.confirmed.rate > 0" :size="16" class="text-xs w-4 h-4" />
                {{ orders.rates.data.confirmed.rate }} %
              </span>
              {{ period }}
            </p>
          </div>
        </div>
      </div>
      <VueApexCharts type="bar" height="350" :options="COPTIONS" :series="orders.rates.series" />
    </div>
    <CostPerDeliveryWidget :costs="orders.costs.data" :series="orders.costs.series" />




    <TbleTags :idWharhouse="idWharhouse" :filterOptions="filterOptions"></TbleTags>


  </div>
</template>

<style>
.mx-input {
  height: 48px;
}

@media (max-width: 768px) {
  .mx-datepicker-range {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .mx-datepicker-range {
    width: 250px;
  }
}
</style>
