var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-col justify-center w-full items-start gap-3 my-5"
  }, [_vm._m(0), _c('DataTable', {
    staticStyle: {
      "border-radius": "10px !important",
      "margin-top": "10px",
      "width": "100%"
    },
    attrs: {
      "value": _vm.AnalyticsperTag,
      "paginator": "",
      "responsiveLayout": "scroll",
      "dataKey": "_id",
      "loading": _vm.isLoading,
      "rows": 10,
      "rowsPerPageOptions": [10, 20, 50],
      "paginatorTemplate": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
      "currentPageReportTemplate": "Showing {first} to {last} of {totalRecords} entries"
    }
  }, [_c('Column', {
    staticStyle: {
      "flex": "0 0 4rem"
    },
    attrs: {
      "field": "details.tag",
      "header": "Tag Name"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var data = _ref.data;
        return [_c('div', {
          staticClass: "bg-green-100 rounded px-3 py-1 flex justify-center items-center"
        }, [_c('span', {
          staticClass: "text-md font-semibold text-green-600"
        }, [_vm._v(" " + _vm._s(data.tagName || data._id) + " ")])])];
      }
    }])
  }), _c('Column', {
    staticStyle: {
      "flex": "0 0 4rem"
    },
    attrs: {
      "field": "details.items",
      "header": "Total Orders"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref2) {
        var data = _ref2.data;
        return [_c('div', {
          staticClass: "flex justify-center items-center"
        }, [_c('span', {
          staticClass: "text-lg font-semibold"
        }, [_vm._v(" " + _vm._s(_vm.getTotalByStatue(data.items, 'total')) + " "), _c('span', {
          staticClass: "text-xs font-normal"
        }, [_vm._v("orders")])])])];
      }
    }])
  }), _c('Column', {
    attrs: {
      "field": "details.items",
      "header": "Delivered Orders"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref3) {
        var data = _ref3.data;
        return [_c('div', {
          staticClass: "flex justify-center items-center"
        }, [_c('span', {
          staticClass: "text-lg font-semibold"
        }, [_vm._v(" " + _vm._s(_vm.getTotalByStatue(data.items, 'delivered')) + " "), _c('span', {
          staticClass: "text-xs font-normal"
        }, [_vm._v("orders")])])])];
      }
    }])
  }), _c('Column', {
    attrs: {
      "field": "details.items",
      "header": "Confirmed Orders"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref4) {
        var data = _ref4.data;
        return [_c('div', {
          staticClass: "flex justify-center items-center"
        }, [_c('span', {
          staticClass: "text-lg font-semibold"
        }, [_vm._v(" " + _vm._s(_vm.getTotalByStatue(data.items, 'confirmed')) + " "), _c('span', {
          staticClass: "text-xs font-normal"
        }, [_vm._v("orders")])])])];
      }
    }])
  }), _c('Column', {
    attrs: {
      "field": "details.CPDTotal",
      "header": "Total cost per delivery"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref5) {
        var data = _ref5.data;
        return [_c('div', {
          staticClass: "flex justify-center items-center"
        }, [_c('span', {
          staticClass: "text-md font-semibold"
        }, [_vm._v(" " + _vm._s(new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(data.CPDTotal)) + " ")])])];
      }
    }])
  }), _c('Column', {
    attrs: {
      "field": "details.CPDAds",
      "header": "Cost per delivery ADS"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref6) {
        var data = _ref6.data;
        return [_c('div', {
          staticClass: "flex justify-center items-center"
        }, [_c('span', {
          staticClass: "text-md font-semibold"
        }, [_vm._v(" " + _vm._s(new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(data.CPDAds)) + " ")])])];
      }
    }])
  }), _c('Column', {
    attrs: {
      "field": "details.CPDCharges",
      "header": "Cost per delivery charges"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref7) {
        var data = _ref7.data;
        return [_c('div', {
          staticClass: "flex justify-center items-center"
        }, [_c('span', {
          staticClass: "text-md font-semibold"
        }, [_vm._v(" " + _vm._s(new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(data.CPDCharges)) + " ")])])];
      }
    }])
  }), _c('Column', {
    attrs: {
      "field": "details.revenues",
      "header": "Revenues"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref8) {
        var data = _ref8.data;
        return [_c('div', {
          staticClass: "flex justify-center items-center"
        }, [_c('span', {
          staticClass: "text-md font-semibold"
        }, [_vm._v(" " + _vm._s(new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(data.revenues)) + " ")])])];
      }
    }])
  }), _c('Column', {
    staticStyle: {
      "flex": "0 0 4rem"
    },
    attrs: {
      "field": "details.profitsPerDelivery",
      "header": "Profits per delivery"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref9) {
        var data = _ref9.data;
        return [_c('div', {
          staticClass: "flex justify-center items-center"
        }, [_c('span', {
          staticClass: "text-md font-semibold"
        }, [_vm._v(" " + _vm._s(new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(data.profitsPerDelivery)) + " ")])])];
      }
    }])
  }), _c('Column', {
    staticStyle: {
      "flex": "0 0 4rem"
    },
    attrs: {
      "field": "details.totalProfits",
      "header": "Profits"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref10) {
        var data = _ref10.data;
        return [_c('div', {
          staticClass: "flex justify-center items-center"
        }, [_c('span', {
          staticClass: "text-md font-semibold"
        }, [_vm._v(" " + _vm._s(new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(data.totalProfits)) + " ")])])];
      }
    }])
  }), _c('Column', {
    attrs: {
      "field": "details.roi",
      "header": "Return on investment"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref11) {
        var data = _ref11.data;
        return [_c('div', {
          staticClass: "flex justify-center items-center gap-2"
        }, [_c('span', {
          staticClass: "text-md font-semibold text-green-500"
        }, [_vm._v(" " + _vm._s(data.roi) + "% ")]), _c('div', {
          staticClass: "relative w-full"
        }, [_c('div', {
          staticClass: "overflow-hidden h-2 text-xs flex rounded bg-green-200"
        }, [_c('div', {
          staticClass: "shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500",
          style: {
            width: data.roi + '%'
          }
        })])])])];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', {
    staticClass: "text-2xl font-bold"
  }, [_vm._v("Product Analytics by tags")]), _c('p', {
    staticClass: "text-gray-600 tracking-wide"
  }, [_vm._v(" Get insights about your product by tags ")])]);

}]

export { render, staticRenderFns }