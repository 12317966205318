var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "stroke-width": "2",
      "stroke": "currentColor",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "stroke": "none",
      "d": "M0 0h24v24H0z",
      "fill": "none"
    }
  }), _c('path', {
    attrs: {
      "d": "M7 7l10 10"
    }
  }), _c('path', {
    attrs: {
      "d": "M17 8l0 9l-9 0"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }