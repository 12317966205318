var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-8 px-6"
  }, [_vm.isLoading ? _c('div', {
    staticClass: "text-3xl font-bold text-center fixed w-full h-full left-0 top-0 right-0 bottom-0 z-50 flex items-center justify-center",
    staticStyle: {
      "background-color": "rgb(0 0 0 / 27%)"
    }
  }, [_c('svg', {
    staticClass: "animate-spin h-12 w-12 text-green-500",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "stroke-width": "2",
      "stroke": "currentColor",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_vm._v(" > "), _c('path', {
    attrs: {
      "stroke": "none",
      "d": "M0 0h24v24H0z",
      "fill": "none"
    }
  }), _c('path', {
    attrs: {
      "d": "M12 3a9 9 0 1 0 9 9"
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "flex items-center justify-between mb-8 flex-wrap gap-6"
  }, [_vm._m(0), _c('div', {
    staticClass: "flex gap-4 flex-wrap w-full md:w-auto"
  }, [_c('Dropdown', {
    staticClass: "w-full md:w-64",
    attrs: {
      "options": _vm.tags,
      "optionLabel": "label",
      "showClear": "",
      "placeholder": "Select Tag"
    },
    model: {
      value: _vm.selectedTag,
      callback: function callback($$v) {
        _vm.selectedTag = $$v;
      },
      expression: "selectedTag"
    }
  }), _c('Vue2DatePicker', {
    staticClass: "w-full md:w-64",
    attrs: {
      "range": "",
      "placeholder": "Select date range",
      "disabled-date": _vm.notAfterToday,
      "shortcuts": _vm.shorts
    },
    model: {
      value: _vm.selectedDates,
      callback: function callback($$v) {
        _vm.selectedDates = $$v;
      },
      expression: "selectedDates"
    }
  })], 1)]), _c('div', {
    staticClass: "bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 my-6"
  }, [_c('div', {
    staticClass: "p-6 grid grid-cols-1 gap-2 sm:gap-0 sm:grid-cols-3 mb-6 space-y-4 md:space-y-0"
  }, [_c('div', [_c('h3', {
    staticClass: "text-lg font-semibold"
  }, [_vm._v("Total Orders")]), _c('div', [_c('p', {
    staticClass: "text-gray-600 tracking-wide"
  }, [_c('span', {
    staticClass: "text-3xl font-bold text-black"
  }, [_vm._v(" " + _vm._s(_vm.orders.rates.data.total.current) + " ")]), _vm._v(" orders ")]), _c('p', {
    staticClass: "text-gray-600 text-sm tracking-wider flex gap-2"
  }, [_c('span', {
    staticClass: "font-bold flex items-center justify-center",
    class: {
      'text-green-500': _vm.orders.rates.data.total.rate > 0,
      'text-red-500': _vm.orders.rates.data.total.rate < 0
    }
  }, [_vm.orders.rates.data.total.rate < 0 ? _c('ArrowDownRight', {
    staticClass: "text-xs w-4 h-4",
    attrs: {
      "size": 16
    }
  }) : _vm._e(), _vm.orders.rates.data.total.rate > 0 ? _c('ArrowUpRight', {
    staticClass: "text-xs w-4 h-4",
    attrs: {
      "size": 16
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.orders.rates.data.total.rate) + " % ")], 1), _vm._v(" " + _vm._s(_vm.period) + " ")])])]), _c('div', [_c('h3', {
    staticClass: "text-lg font-semibold"
  }, [_vm._v("Delivered Orders")]), _c('div', [_c('p', {
    staticClass: "text-gray-600 tracking-wide"
  }, [_c('span', {
    staticClass: "text-3xl font-bold text-black"
  }, [_vm._v(" " + _vm._s(_vm.orders.rates.data.delivered.current) + " ")]), _vm._v(" orders ")]), _c('p', {
    staticClass: "text-gray-600 text-sm tracking-wider flex gap-2"
  }, [_c('span', {
    staticClass: "font-bold flex items-center justify-center",
    class: {
      'text-green-500': _vm.orders.rates.data.delivered.rate > 0,
      'text-red-500': _vm.orders.rates.data.delivered.rate < 0
    }
  }, [_vm.orders.rates.data.delivered.rate < 0 ? _c('ArrowDownRight', {
    staticClass: "text-xs w-4 h-4",
    attrs: {
      "size": 16
    }
  }) : _vm._e(), _vm.orders.rates.data.delivered.rate > 0 ? _c('ArrowUpRight', {
    staticClass: "text-xs w-4 h-4",
    attrs: {
      "size": 16
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.orders.rates.data.delivered.rate) + " % ")], 1), _vm._v(" " + _vm._s(_vm.period) + " ")])])]), _c('div', [_c('h3', {
    staticClass: "text-lg font-semibold"
  }, [_vm._v("Confirmed Orders")]), _c('div', [_c('p', {
    staticClass: "text-gray-600 tracking-wide"
  }, [_c('span', {
    staticClass: "text-3xl font-bold text-black"
  }, [_vm._v(" " + _vm._s(_vm.orders.rates.data.confirmed.current) + " ")]), _vm._v(" orders ")]), _c('p', {
    staticClass: "text-gray-600 text-sm tracking-wider flex gap-2"
  }, [_c('span', {
    staticClass: "font-bold flex items-center justify-center",
    class: {
      'text-green-500': _vm.orders.rates.data.confirmed.rate > 0,
      'text-red-500': _vm.orders.rates.data.confirmed.rate < 0
    }
  }, [_vm.orders.rates.data.confirmed.rate < 0 ? _c('ArrowDownRight', {
    staticClass: "text-xs w-4 h-4",
    attrs: {
      "size": 16
    }
  }) : _vm._e(), _vm.orders.rates.data.confirmed.rate > 0 ? _c('ArrowUpRight', {
    staticClass: "text-xs w-4 h-4",
    attrs: {
      "size": 16
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.orders.rates.data.confirmed.rate) + " % ")], 1), _vm._v(" " + _vm._s(_vm.period) + " ")])])])]), _c('VueApexCharts', {
    attrs: {
      "type": "bar",
      "height": "350",
      "options": _vm.COPTIONS,
      "series": _vm.orders.rates.series
    }
  })], 1), _c('CostPerDeliveryWidget', {
    attrs: {
      "costs": _vm.orders.costs.data,
      "series": _vm.orders.costs.series
    }
  }), _c('TbleTags', {
    attrs: {
      "idWharhouse": _vm.idWharhouse,
      "filterOptions": _vm.filterOptions
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', {
    staticClass: "text-2xl font-bold"
  }, [_vm._v("Product Analytics")]), _c('p', {
    staticClass: "text-gray-600 tracking-wide"
  }, [_vm._v(" Get insights about your product ")])]);

}]

export { render, staticRenderFns }