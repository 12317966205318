import axios from "../common/axios";

export async function productsAnalytics(params = {}) {
  const { data } = await axios.get("/products/productsAnalytics", {
    params,
  });
  return data;
}

export async function getProduct(id) {
  const { data } = await axios.get(`/products/get?id=${id}`);
  return data;
}

export async function getProducts(params) {
  const { data } = await axios.get("/products/search", { params });
  return data;
}
export async function deleteProduct(id) {
  const { data } = await axios.delete(`/products/delete?id=${id}`);
  return data;
}
